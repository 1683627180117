@import './../../../variables/variables.scss';
.card{
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    // transform:scale(1.0);
    // transform: scaleY(1.0);
    // transform: scaleX(1.0);
    // transition: 0.2s;
    // display: block;
    // -webkit-transition: opacity 1s ease-in-out;
    // -moz-transition: opacity 1s ease-in-out;
    // -ms-transition: opacity 1s ease-in-out;
    // -o-transition: opacity 1s ease-in-out;
    // transition: opacity 1s ease-in-out;

    // transform:scale(1.0);
    // border: olive solid 1px;
    display: block;
    // transition: 1.0s;
}
.card.enable{
    display:block;
}
.card.disable{
    // transition: 1.0s;
    // display: block;
    // transform: scaleY(0.0);
    // transform: scaleX(0.0);
    // transform:scale(0.0);
    // transition: 0.2s;
    // filter: alpha(opacity=50);
    // opacity: 0.5;
    // transform:scale(0.1);
    display:none;
}

.img_box{
    position: relative;
    width: 100%;
    height: auto;
    filter: grayscale(0);
}
.overlay{
    display: block;
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background:rgba($secondary, $alpha: 0.0);
}
@media(hover: hover) and (pointer: fine){
    .card:hover{
        transition: 0.1s;
        // transform:scale(1.01);
        // clip-path: circle(100px at center);
    }
    .card:hover .img_box{
        filter: grayscale(1);
    }
    .card:hover .overlay{
        transition: 0.1s;
        background:rgba($secondary, $alpha: 0.5);
        // clip-path: circle(100px at center);
    }
}
.bar{
    background-color: $secondary;
    display: block;
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: right;
    color: $baseline_on_secondary;
    bottom:0;
    left: 0;
    // clip-path: polygon(100% 0%, 10% 0%, 0% 100%, 100% 100%);
    transform: scaleY(0);
    transform-origin: bottom;
}
@media(hover: hover) and (pointer: fine){
    .card:hover .bar{
        transition: 0.2s;
        transform: scaleY(1);
    }
}
.bar p {
    margin-right: 30px;
}