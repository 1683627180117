.App {
  /* background-color: aqua; */
  width: 100%;
  height: 100%;
  min-width: 375px;
  display: flex;
  flex-flow: row;
  /* flex-flow: row wrap; */
  /* flex-flow: column; */
}
.App.mobile{
  flex-flow: row wrap;
}
.App.smallscreen{
  /* flex-flow: row wrap; */
}