@import './../../variables/variables.scss';
.contact_container{
    margin: 0;
    width: calc(70% - $headerNav_width);
    height: 100%;
    margin: auto;
}
.contact_container.mobile{
    width: 90%;
    margin: auto;
}
.contact{
    max-width: 800px;
    flex-flow: column;
    margin:auto;
    margin-top: 20px;
    margin-bottom: 100px;
    color: #{$primary_dark};
}
.contact.twoColumn{
    width: 70%;
}
.contact p{
    line-height: 1.5em;
}
.contact_title{
    width: 100%;
    font-size: 2em;
    text-align: center;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 40px;
    
}
.contact_box{
    width: 100%;
}
.contact_box li{
    width: 100%;
    display: flex;
    height: 3em;
    line-height: 3em;    
    margin-bottom: 10px;
}
.contact_box li a:first-child{
    width: 50px;
    height: 50px;
    margin-right: 10px;
    text-align: center;
    font-size: 2em;
}
.contact_box li a:first-child{
    color: #{$primary_dark};
}
@media(hover: hover) and (pointer: fine){
    .contact_box li:hover > a{
        color:#{$secondary};
    }
    .contact_box li:hover > a:nth-child(2){
        background-color: #{$secondary};
        color:#{$baseline_on_secondary};
    }
}
.contact_box li a:nth-child(2){
    width: 100% !important;
}
.contact_box li a:nth-child(2){
    display: block;
    background-color: #{$primary};
    color:#{$baseline_on_primary};
    padding-left: 40px;
    border-radius: 50px;
}