@import './../../variables/variables.scss';
.portfolio{
    width: calc(100% - $headerNav_width);
    display: flex;
    flex-flow: column;
    // align-content: center;
    // align-content: flex-start;
    // border: olive solid 1px;
}
.portfolio.mobile{
    width: 100%;
}
.title_portfolio{
    width: 100%;
    color: #{$primary_dark};
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 40px;
    // border: olivedrab solid 1px;
}
.gallery{
    width: 95%;
    align-self: center;
    -webkit-column-count: 4;
    /* -webkit-column-gap: 0px; */
    -moz-column-count: 4;
    /* -moz-column-gap: 0px; */
    column-count: 4;
    column-gap: 10px;
    // border: steelblue solid 1px;
    // border: steelblue solid 1px;
}
.gallery.threeColumn{
    -webkit-column-count: 3;
    /* -webkit-column-gap: 0px; */
    -moz-column-count: 3;
    /* -moz-column-gap: 0px; */
    column-count: 3;
    column-gap: 10px;
    // border: steelblue solid 1px;
}
.gallery.twoColumn{
    width: 90%;
    -webkit-column-count: 2;
    /* -webkit-column-gap: 0px; */
    -moz-column-count: 2;
    /* -moz-column-gap: 0px; */
    column-count: 2;
    column-gap: 10px;
}
.gallery.oneColumn{
    -webkit-column-count: 1;
    /* -webkit-column-gap: 0px; */
    -moz-column-count: 1;
    /* -moz-column-gap: 0px; */
    column-count: 1;
    column-gap: 0px;
}
