$primary: #cfd8dc;
// $primary: rgba(255,255,255,50);
$primary_dark: #9ea7aa;
$primary_light: #ffffff;

$secondary: #ffa726;
$secondary_dark: #c77800;
$secondary_light: #ffd95b;

$background:#CCCCCC;
$surface:#AAAAAA;
$error:#FF0000;

$baseline_on_primary: #757575;
$baseline_on_secondary: #ffecb3;

$headerNav_width:260px;
$headerNav_folded_height:60px;
$headerNav_unfolded_height:260px;
