@import './../../variables/variables.scss';
.about_container{
    margin: 0;
    width: calc(100% - $headerNav_width);
    height: 100%;
}
.about_container.mobile{
    width: 90%;
    margin: auto;
}

.about{
    max-width: 800px;
    flex-flow: column;
    margin:auto;
    margin-top: 20px;
    margin-bottom: 100px;
    color: #{$primary_dark};
}
.about ul {
    // display: flex;
    margin-top: 10px;
}
.about li{
    margin-left: 30px;
    line-height: 1.5em;
}
.about.twoColumn{
    width: 70%;
}
.about p{
    line-height: 1.5em;
}
.about_title{
    width: 100%;
    font-size: 2em;
    text-align: center;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 40px;
    
}
.about_yearMarker{
    color: #{$primary_dark};
    height: auto;
    font-weight: bold;
    border-bottom: #{$primary} solid 2px;
    // border-bottom: #{$secondary_light} solid 2px;
    margin-bottom: 10px;
    padding: 10px;
    padding-bottom: 5px;
}

.about_yearMarker span{
    // color: #{$baseline_on_primary};
    // color: #{$primary_dark};
    margin-left: 20px;
    // font-weight: bold;
    // font-size: 1.1em;
}
.about_section_title{
    border-radius: 30px;
    background-color: #{$primary};
    padding: 10px;
    padding-right: 15px;
    width: 97%;
    color: #{$primary_dark};
    font-size: 1.7em;
    text-align: right;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 10px;
    
}
.about_section_title.oneColumn{
    text-align: center;
    padding-right: 0px;
}
hr{
    // border:#{$primary} solid 0.5px;
}
.about_timeline{
    // line-height: 1.5em;
    
}
.about_timeline p{
    margin-left: 50px;
    // width: 95%;
    // border: chocolate solid 1px;
}
.about_yearSection{
    margin-top: 30px !important;

}
.about_yearSection.first{
    margin-top: 0px !important;
}
h1+div{
    margin-top: 0px !important;
}
.about_yearSection p:nth-child(2){
    font-weight: bold;
    // margin-left: 15px;
}
.about_yearSection{
    width: 90%;
    margin:auto;
}

.about_timeline li{
    margin-left: 100px;
    line-height: 1.5em;
}
.not{
    font-weight: normal !important;
}
a{
    color: #{$secondary};
}